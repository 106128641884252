import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom';

const usePnrFlight = (verifyCaptcha) => {

    const [errorReservation, setErrorReservation] = useState("")
    const [loadingPnr, setLoadingPnr] = useState(false)
    const [modalSucessor, setModalSucessor] = useState(false)
    const [travellers, setTravellers] = useState([])
    const persons = []
    const childs = []
    const babys = []
    const [typeFlight, setTypeFlight] = useState("")
    const arrival = localStorage.getItem("iata-destiny")
    const departure = localStorage.getItem("iata-origin")
    const { state } = useLocation()
    const navigate = useNavigate();
    const [saveInfo, setSaveInfo] = useState(false)
    const [codePNR, setCodePNR] = useState('')

    const volverPaginaAnterior = () => {
        navigate(-1);
    };

    const notify = (title, icon) =>
        toast(title, {
            icon: icon,
        });

    const cantPass = state?.prices.filter(item => item.type !== "INF")

    function formatearCantidad(cantidad) {
        const cantidadFormateada = cantidad?.toLocaleString("es", {
            style: "currency",
            currency: "COP",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        return cantidadFormateada;
    }


    const buildFree = (date, sex) => {
        // Separar la fecha en año, mes y día
        const [year, month, day] = date?.split('-');

        // Array con los nombres de los meses abreviados
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

        // Obtener la abreviatura del mes según el número de mes
        const monthAbbr = months[parseInt(month) - 1];

        // Obtener los últimos dos dígitos del año
        const yearAbbr = year?.slice(-2);

        // Concatenar los componentes para formar el formato deseado
        const formattedDate = `----${day}${monthAbbr}${yearAbbr}-${sex}--`;

        return formattedDate;
    };

    const buildFech = (date) => {
        // Separar la fecha en año, mes y día
        const [year, month, day] = date?.split('-');

        // Array con los nombres de los meses abreviados
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

        // Obtener la abreviatura del mes según el número de mes
        const monthAbbr = months[parseInt(month) - 1];

        // Obtener los últimos dos dígitos del año
        const yearAbbr = year?.slice(-2);

        // Concatenar los componentes para formar el formato deseado
        const formattedDate = `${day}${monthAbbr}${yearAbbr}`;

        return formattedDate;
    };

    const buildTravellers = () => {
        const travellersNew = [];
    
        travellers.forEach((item, key) => {
            const travellerData = {
                number: key + 1,
                free: buildFree(item?.birth, item?.sex),
                name: item?.name,
                lastName: item?.lastname,
                type: item?.typeDoc,
                document: item?.doc,
                expiration: typeFlight === false ? "" : buildFech(item?.expiration),
                birthDay: buildFech(item?.birth),
                sex: item?.sex,
                country: item?.country,
                baby: null, // Inicializa como null
            };
    
            // Verificar el tipo de viajero y asignar la propiedad correspondiente
            if (item?.email) {
                travellerData.passengerType = item?.adult;
            } else if (item.child) {
                travellerData.passengerType = item?.child;
            } else if (item?.seat) {
                travellerData.passengerType = item?.seat;
            } else if (item?.baby) {
                // Asignar el bebé a un adulto existente
                for (let index = 0; index < travellersNew.length; index++) {
                    if (travellersNew[index].passengerType === "ADT" && !travellersNew[index].baby) {
                        travellersNew[index].baby = {
                            ...travellerData,
                            number: travellersNew[index].number, // Mantener el número del adulto
                        };
                        return; // Salir del loop ya que se asignó el bebé
                    }
                }
                return; // Si no se encuentra un adulto, no agregar
            } else {
                travellerData.passengerType = item?.adult;
            }
    
            // Actualizar o agregar el viajero al arreglo
            const existingTravellerIndex = travellersNew.findIndex(trav => 
                trav.name === travellerData.name && trav.lastName === travellerData.lastName
            );
    
            if (existingTravellerIndex !== -1) {
                // Actualizar el viajero existente
                travellersNew[existingTravellerIndex] = { ...travellersNew[existingTravellerIndex], ...travellerData };
            } else {
                // Agregar nuevo viajero
                travellersNew.push(travellerData);
            }
        });
    
        return travellersNew;
    };
    





    const savePNRN = () => {

        const hasDuplicateDocuments = buildTravellers().some((traveller, index, self) =>
            index !== self.findIndex((t) => t.document === traveller.document)
        );

        const url = `${process.env.REACT_APP_URL_API_FLY}savepnr`;

        if (hasDuplicateDocuments) {
            notify("Hay pasajeros con el mismo número de documento", "❌");
            setLoadingPnr(false);
            return;
        }
        
        if (!travellers[0]?.email || travellers.length < state?.passengersSelected?.adult + state?.passengersSelected?.boy + state?.passengersSelected?.seat || !travellers[0]?.tel) {
            notify("Datos incompletos para procesar la solicitud", "❌")
            setLoadingPnr(false)
        } else {
            if (verifyCaptcha()) {
                setLoadingPnr(true)
                const requestPNR = {
                    "company": state?.company,
                    "origin": "wellezy",
                    "bag": state?.bag,
                    "fare": state?.fare,
                    "international": typeFlight,
                    "tel": travellers[0]?.tel,
                    "email": travellers[0]?.email?.replace("@", "//"),
                    "emailTwo": travellers[0]?.email,
                    "travellers": buildTravellers(),
                    "itinerary": state?.itinerary
                }
               
                axios.post(url, requestPNR)
                    .then(res => {
                        const controlNumberPNR = res.data?.reserve?.control_number
                        if (controlNumberPNR !== undefined) {
                            localStorage.setItem('codePNR', controlNumberPNR);
                        }

                        if (controlNumberPNR) {
                            setLoadingPnr(false)
                            navigate("/flight/methodPay", {
                                state: {
                                    requestPNR: requestPNR,
                                    controlNumberPNR: controlNumberPNR,
                                    priceTotal: state?.priceTotal,
                                    priceTaxes: state?.priceTaxes,
                                    priceWithOutTaxes: state?.priceWithOutTaxes,
                                    currency: state?.currency
                                }
                            })
                        } else {
                            setCodePNR(localStorage.getItem('codePNR'))
                            setLoadingPnr(false)
                            setModalSucessor(true)
                        }
                    })
                    .catch(err => {
                        setErrorReservation("err.response.data.error[0].text")
                        notify(err.response.data.error[0].text, "❌")
                        setLoadingPnr(false)
                    })
            } else {
                setLoadingPnr(false)
                notify("Debes verificar el reCAPTCHA", "❌")
            }

        }
    }


    function checkTypeFlight() {
        setLoadingPnr(true)
        const url = `${process.env.REACT_APP_URL_API_FLY}airport/iata`;
        axios.post(url, {

            departure: departure,
            arrival: arrival
        })
            .then(res => {
                setLoadingPnr(false)
                setTypeFlight(res.data?.international)
            })
            .catch(err => {
                console.log(err)
                setLoadingPnr(false)
                notify("Error en la verificacion de vuelo", "❌")
            })
    }

    useEffect(() => {
        checkTypeFlight()
    }, [])


    return {
        savePNRN,
        formatearCantidad,
        volverPaginaAnterior,
        state,
        persons,
        childs,
        loadingPnr,
        babys,
        errorReservation,
        buildTravellers,
        travellers,
        setTravellers,
        typeFlight,
        saveInfo,
        setSaveInfo,
        setModalSucessor,
        modalSucessor,
        codePNR
    }
}

export default usePnrFlight